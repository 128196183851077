// Add your custom JS here.

jQuery(function($) {

  /////ヘッダー固定時のスムーススクロール
  const header = $('header');

  $('a[href^="#"]').on('click', function() {
    const gap = header.outerHeight();
    const speed = 500;
    const href = $(this).attr("href");
    const target = $(href == "#" || href == "" ? "html" : href);
    const position = target.offset().top - gap;

    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });

  /////ウィンドウサイズ992px以下のときは、右側の「お問い合わせ」を非表示にする
  const mediaQuery = window.matchMedia('(max-width: 992px)');

  // ページが読み込まれた時に実行
  handle(mediaQuery);

  // ウィンドウサイズを変更しても実行（ブレイクポイントの監視）
  mediaQuery.addListener(handle);

  function handle(mm) {
    if (mm.matches) {
      // ウィンドウサイズ992px以下のときの処理
      const element = document.getElementById("target");
      element.parentNode.removeChild(element);
    }
  }

  ///// Collapse responsive navbar when toggler is visible
  const navbarToggler = document.body.querySelector('.navbar-toggler');
  const responsiveNavItems = [].slice.call(
      document.querySelectorAll('#navbarResponsive .nav-link')
  );
  responsiveNavItems.map(function (responsiveNavItem) {
      responsiveNavItem.addEventListener('click', () => {
          if (window.getComputedStyle(navbarToggler).display !== 'none') {
              navbarToggler.click();
          }
      });
  });

  ///// フッターナビの表示
  var pagetop = $('#footer-navi');
  // ボタン非表示
  pagetop.hide();
  // 600px スクロールしたらボタン表示
  $(window).scroll(function () {
     if ($(this).scrollTop() > 600) {
          pagetop.fadeIn();
     } else {
          pagetop.fadeOut();
     }
  });

});

////
window.addEventListener('DOMContentLoaded', event => {

    // Navbar shrink function
    var navbarShrink = function () {
        const navbarCollapsible = document.body.querySelector('#wrapper-navbar');
        if (!navbarCollapsible) {
            return;
        }
        if (window.scrollY === 0) {
            navbarCollapsible.classList.remove('navbar-shrink')
        } else {
            navbarCollapsible.classList.add('navbar-shrink')
        }

    };

    // Shrink the navbar
    navbarShrink();

    // Shrink the navbar when page is scrolled
    document.addEventListener('scroll', navbarShrink);

    // Activate Bootstrap scrollspy on the main nav element
    const mainNav = document.body.querySelector('#main-nav');
    if (mainNav) {
        new bootstrap.ScrollSpy(document.body, {
            target: '#main-nav',
            rootMargin: '0px 0px -40%',
        });
    };

});
